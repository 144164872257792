import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'ladda/dist/ladda.min.css';

import "./index.css"

import reportWebVitals from './reportWebVitals';

import configureStore from './appredux/store';
import {Provider} from 'react-redux';

export const store = configureStore();


ReactDOM.render(
    <Provider store={store}>
        <App/>

        {/*<React.StrictMode>*/}
        {/*</React.StrictMode>*/}
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
