import React from "react";
import './App.css';
import RouteComponent from "./route/route.component";
import {Location} from "@reach/router";
import { app } from './firebase-config';

function App() {
  return (
      <Location>
        {({location}) => (
            <RouteComponent location={location}/>
        )}
      </Location>
  );
}

export default App;
