export const LANDING = '/'
export const LOGIN = '/login'
export const DASHBOARD = '/dashboard'
export const EVENTS = '/events'
export const VIDEOS = '/videos'
export const LIVE = '/live'
export const AUDIOS = '/audios'
export const NOTIFICATIONS = '/notifications'
export const SURVEYS = '/surveys'
export const DONATIONS = '/donations'
export const SOCIAL_MEDIA = '/social-media'
export const QUIZ = '/quiz'
export const QUIZ_QUESTION_PATH = '/quiz/:id'
export const START_QUIZ = '/quiz/start/:id'
