import {storageService} from "../storage"

import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth'

export const authenticationService = {
    authenticateUser
};

function authenticateUser(email, password) {
    const firebaseAppAuth = getAuth();
    return signInWithEmailAndPassword(firebaseAppAuth, email, password)
}