import { authenticationConstants } from '../constants';

const INIT_STATE = {
    sessionData: {},
    loggedIn: false,
    errorMessage: false
}

export function authenticationReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case authenticationConstants.LOGIN_REQUEST:
            return {
                loggedIn: false,
                sessionData: action.sessionData,
                errorMessage: ""
            };
        case authenticationConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                sessionData: action.sessionData,
                errorMessage: ""
            };
        case authenticationConstants.LOGIN_FAILURE:
            return {
                loggedIn: false,
                errorMessage: action.error
            };
        case authenticationConstants.LOGOUT:
            return {};
        default:
            return state
    }
}