import React from 'react';
import {Layout, Menu, Input, Typography} from 'antd';
import LinkComponent from "../../route/link.component";
import Alert from "../alert/alert";
import {BrowserRouter as Router, Link} from 'react-router-dom'

import {
    CalendarOutlined,
    VideoCameraAddOutlined,
    AudioOutlined,
    PlaySquareOutlined,
    PlaySquareTwoTone,
    ReconciliationOutlined,
    TeamOutlined,
    NotificationOutlined,
    ProfileOutlined,
    DashboardOutlined
} from '@ant-design/icons'

import * as ROUTES from "../../constants/routes";


const {Header, Content, Footer, Sider} = Layout;
const { Search } = Input;
const { Title } = Typography;


const Landing = (Component) => (props) => {
    console.log("Landing")

    return <Router>
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%', background: '#fff', padding: 0}}>
                <Title level={3} style={{ cursor:'pointer', float:'left',  paddingLeft: '20px', paddingTop: '10px', color: '#000' }}>New Covenant Assembly</Title>
                {/*<LogOutButton />*/}
            </Header>
            <Layout>
                <Sider collapsed={false} width={230} theme='light' style={{
                    overflow: 'auto',
                    marginTop: '60px',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,}}>
                    <Menu theme='light' mode='inline' defaultSelectedKeys={['1']}>
                        <Menu.Item key='1'  icon={<DashboardOutlined />}>
                            <Link to={ROUTES.DASHBOARD} >
                                <span className='nav-text'>Dashboard</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key='2' icon={<CalendarOutlined/> }>
                            <Link to={ROUTES.EVENTS}>
                                <span className='nav-text'>Events</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key='3' icon={<VideoCameraAddOutlined/> }>
                            <Link to={ROUTES.VIDEOS}>
                                <span className='nav-text'>Videos</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key='4' icon={<AudioOutlined/> }>
                            <Link to={ROUTES.AUDIOS}>
                                <span className='nav-text'>Audios</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key='5' icon={<PlaySquareOutlined/> }>
                            <Link to={ROUTES.LIVE}>
                                <span className='nav-text'>Live</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key='6'  icon={<PlaySquareTwoTone/>}>
                            <Link to={ROUTES.SURVEYS}>
                                <span className='nav-text'>Surveys</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key='7'  icon={<TeamOutlined/>}>
                            <Link to={ROUTES.SOCIAL_MEDIA}>
                                <span className='nav-text'>Social Media</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key='8'  icon={<NotificationOutlined/>}>
                            <Link to={ROUTES.NOTIFICATIONS}>
                                <span className='nav-text'>Notifications</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key='9' icon={<ProfileOutlined/>}>
                            <Link to={ROUTES.QUIZ}>
                                <span className='nav-text'>Quiz</span>
                            </Link>
                        </Menu.Item>
                    </Menu>
                </Sider>

                <Layout style={{paddingTop: 0}}>
                    <Content style={{margin: '8px 8px 0', overflow: 'initial'}}>
                        <div className="site-layout-background" style={{padding: 8, minHeight: '100vh'}}>
                            <Component {...props}/>
                        </div>
                    </Content>
                    {/*<Footer style={{textAlign: 'center'}}> ©2021 Created by Dev</Footer>*/}
                </Layout>
            </Layout>

        </Layout>
    </Router>
}

// const actionCreators = {
// };
//
// function mapStateToProps(state) {
//     const { profiles } = state.profileReducer
//     return { profiles };
// }
//
//
// export default connect(mapStateToProps, {})(landing);

export default Landing;
