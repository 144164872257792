import React, {useState} from 'react';
import {Col, Row, Spin, Input, Alert, Image, Card, Form, Button} from "antd";
import _, {values} from "lodash";
import {connect} from "react-redux";
import Icon from "@ant-design/icons";
import {isEmail} from "../../utils/validation";

import { authenticationActions } from '../../appredux/actions';


function Dashboard(props) {

    return (
        <div style={{height: '100vh', backgroundColor: '#19012b'}}>
            Better
        </div>
    );
}

const actionCreators = {
    authenticateUser: authenticationActions.login,
    logout: authenticationActions.logout
};

function mapStateToProps(state) {
    const { loggedIn, sessionData, errorMessage } = state.authenticationReducer;
    return { loggedIn, sessionData, errorMessage };
}


export default connect(mapStateToProps, actionCreators)(Dashboard);
