import { authenticationService } from "../services";
import {redirectToDashboard} from "../../route/redirect";
import { authenticationConstants, alertConstants} from "../constants";
import {storageConstants, storageService} from "../storage";


export const authenticationActions = {
    login,
    logout
};

function login(email, password) {
    return dispatch => {
        dispatch(request({ }));

        authenticationService.authenticateUser(email, password)
            .then(response => {
                    storageService.compressSaveToStore(storageConstants.USER_INFO, response)
                    redirectToDashboard().then(r => "")
                },
                error => {
                    console.log(error.toString())
                    dispatch(failure(error.toString()));
                    dispatch(failures(error.toString()));
                }
            );
    };

    function failures(error) { return { type: alertConstants.ERROR, error } }
    function request(sessionData) { return { type: authenticationConstants.LOGIN_REQUEST, sessionData } }
    function failure(error) { return { type: authenticationConstants.LOGIN_FAILURE, error } }
}

function logout() {
    //authenticationService.logout();
    //return { type: authenticationService.LOGOUT };
}