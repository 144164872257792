import {
    Login,
    Dashboard
} from "../containers";
import {Access, Landing} from "../layouts";

const Routes = [
    {path: "/login", component: Login, layout: Access, auth: false},
    // {path: "/logout"},
    //
    {path: "/", component: Login, layout: Access, auth: true},
    {path: "/dashboard", component: Dashboard, layout: Landing, auth: true},
    // {path: "/new", component: New, layouts: landing, auth: true},
    // {path: "/chats", component: New, layouts: landing, auth: true},
];

export default Routes;
export {default as RouteComponent} from "./route.component";
