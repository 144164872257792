import { combineReducers } from 'redux';

// import Auth from './Auth';
import { authenticationReducer } from './authentication.reducer';
import { alertReducer } from './alert.reducer';

const reducers = combineReducers({
    // Auth,
    authenticationReducer,
    alertReducer,
    // profileReducer
});

export default reducers;
