import React from "react";
import _ from "lodash"
import {Redirect} from "@reach/router";
import {storageService} from "../appredux/storage";

function AppComponent(props) {
    let {component, layout, route, auth} = props;
    const {path} = route;

    if(_.trim(route.path) !== "/") {
        if(storageService.requiredToLogin()) {
            return <Redirect from={path} to='/' noThrow />;
        }
    }
    console.log('authRequired', _.trim(route.path));
    console.log('authRequired', auth);
    console.log('authRequired',storageService.requiredToLogin());
    console.log('layout', layout);

    const data = {};
    component = (typeof layout != "undefined") ? layout(component, {data}) : component;

    return React.createElement(component, {
        path: path,
        props: props,
        ...props,
    });
}

export default AppComponent;
