import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyAfzm4vqz2BkaPnwU-8G2lxnoy5fymEYgg",
    authDomain: "new-convenant-assembly.firebaseapp.com",
    databaseURL: "https://new-convenant-assembly.firebaseio.com",
    projectId: "new-convenant-assembly",
    storageBucket: "new-convenant-assembly.appspot.com",
    messagingSenderId: "861965979536",
    appId: "1:861965979536:web:6988c8632fb70756",
    measurementId: "G-YKRR3PB1FC"
};

export const app = initializeApp(firebaseConfig);
