import React, {useState} from 'react';
import {Col, Row, Spin, Input, Alert, Image, Card, Form, Button} from "antd";
import _, {values} from "lodash";
import {connect} from "react-redux";
import Icon from "@ant-design/icons";
import {isEmail} from "../../utils/validation";
import logoImg from "../../images/logo_1.png";

import { authenticationActions } from '../../appredux/actions';


function Login(props) {

    const [submitted, setSubmitted] = useState(false);
    const [form] = Form.useForm();

    const onFormSubmit = (values) => {
        console.log(values)
        if (!_.isEmpty(values.email) && !_.isEmpty(values.password)) {
            setSubmitted(true)
            console.log(`onSubmitChatUpdated`)
            props.authenticateUser(values.email, values.password)
        }
    }


    return (
        <Row style={{height: '100vh', backgroundColor: '#ffffff'}}>

            <Col span={19} style={{height: '100vh', backgroundColor: '#ffffff'}}>
                <Row style={{padding: '10px'}}>
                    <Image height={100} src={logoImg}/>
                </Row>
            </Col>
            {/*<Col span={5} className="login-div">*/}
            {/*    <div style={{*/}
            {/*        display: 'flex',*/}
            {/*        justifyContent: 'space-around',*/}
            {/*        alignItems: 'center',*/}
            {/*        width: '100vw',*/}
            {/*        height: '100vh'*/}
            {/*    }}>*/}
            {/*        <div style={{width: "100%", padding: "40px"}}>*/}

            {/*            { props.errorMessage && <Alert*/}
            {/*                message={props.errorMessage}*/}
            {/*                type="error"*/}
            {/*                closable*/}
            {/*            /> }*/}
            {/*            <p/>*/}
            {/*            <b>Prestige Builders</b>*/}
            {/*            <p/>*/}
            {/*            <Form form={form}*/}
            {/*                  onFinish={onFormSubmit} initialValues={{*/}
            {/*                email: '',*/}
            {/*                password: ''*/}
            {/*            }} className='login-form'>*/}
            {/*                <Form.Item name='email' rules={[*/}
            {/*                    () => ({*/}
            {/*                        validator(rules, value) {*/}
            {/*                            if (_.isEmpty(value)) {*/}
            {/*                                return Promise.reject(new Error('Email address is required'));*/}
            {/*                            } else if (!isEmail(value)) {*/}
            {/*                                return Promise.reject(new Error('Email address is invalid'));*/}
            {/*                            }*/}
            {/*                            return Promise.resolve();*/}
            {/*                        },*/}
            {/*                    }),*/}
            {/*                ]}>*/}
            {/*                    <Input*/}
            {/*                        prefix={<Icon type='user' style={{color: 'rgba(0,0,0,.25)'}}/>}*/}
            {/*                        placeholder='Email Address'*/}
            {/*                        type="text"/>*/}
            {/*                </Form.Item>*/}
            {/*                <p/>*/}
            {/*                <Form.Item name="password" rules={[*/}
            {/*                    () => ({*/}
            {/*                        validator(rules, value) {*/}
            {/*                            if (_.isEmpty(value)) {*/}
            {/*                                return Promise.reject(new Error('Password is required'));*/}
            {/*                            }*/}
            {/*                            return Promise.resolve();*/}
            {/*                        },*/}
            {/*                    }),*/}
            {/*                ]}>*/}
            {/*                    <Input*/}
            {/*                        prefix={<Icon type='lock' name='password' style={{color: 'rgba(0,0,0,.25)'}}/>}*/}
            {/*                        type='password'*/}
            {/*                        placeholder='Password'/>*/}
            {/*                </Form.Item>*/}
            {/*                <p/>*/}
            {/*                <Form.Item>*/}
            {/*                    <Button*/}
            {/*                        loading={submitted}*/}
            {/*                        type='primary' htmlType='submit' className='login-form-button'>*/}
            {/*                        Log in*/}
            {/*                    </Button>*/}
            {/*                </Form.Item>*/}

            {/*                /!*{error && <p>{error.message}</p>}*!/*/}

            {/*            </Form>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Col>*/}
        </Row>
    );
}

const actionCreators = {
    authenticateUser: authenticationActions.login,
    logout: authenticationActions.logout
};

function mapStateToProps(state) {
    const { loggedIn, sessionData, errorMessage } = state.authenticationReducer;
    return { loggedIn, sessionData, errorMessage };
}


export default connect(mapStateToProps, actionCreators)(Login);
