import {useDispatch, useSelector} from "react-redux";
import {message} from "antd";
import {useEffect} from "react";
import {alertActions} from "../../appredux/actions";
import { notification } from 'antd';

function Alert(props) {

    const alertErrorKey = "alertErrorKey"
    const alerts = useSelector(state => state.alertReducer)
    const alertDispatch = useDispatch()

    useEffect(() =>{

        if(alerts?.type === "alert-danger") {
            message.error({ content: alerts?.message, key:alertErrorKey }).then(r =>  alertDispatch(alertActions.clear()))
        } else {
            message.destroy(alertErrorKey)
        }

        if(alerts?.type === "alert-notification" && alerts?.message !== undefined && alerts?.message !== null && alerts?.message !== "") {
            notification.open({
                duration: 0,
                message: "New Message Received",
                placement: 'topRight',
                description: alerts?.message,
            });

            let permission = Notification.permission;
            if(permission === "granted") {
                showNotification(alerts?.message);
            } else if(permission === "default"){
                requestAndShowPermission(alerts?.message);
            } else {
                alert("Use normal alert");
            }

            function requestAndShowPermission(message) {
                Notification.requestPermission(function (permission) {
                    if (permission === "granted") {
                        showNotification(message);
                    }
                }).then(r => "");
            }

            function showNotification(body) {
                if(document.visibilityState === "visible") {
                    return;
                }
                // const icon = likeIcon
                // const notification = new Notification('New Message', {body, icon});
                // notification.onclick = () => {
                //     notification.close();
                //     window.parent.focus();
                // }
            }
        }
    })
    return <>
    </>
}

export default Alert