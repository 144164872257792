import _ from 'lodash'

const compressor = require('lzutf8');

export const storageConstants = {
    USER_LOGGED_IN: '__user__logged_in__',
    LOGIN_INFO: '__login_info__',
    USER_INFO: '__user_info__',
    USER_REFRESH_TOKEN: '__user_refresh_token__'
};

export const storageService = {
    getFromStore,
    saveToStore,
    deleteFromStore,
    compressSaveToStore,
    decompressFromStore,
    requiredToLogin
};

function getFromStore(key) {
    return localStorage.getItem(key)
}

function saveToStore(key, value) {
    return localStorage.setItem(key, value)
}


function compressSaveToStore(key, value) {
    console.log(`Key ${key}`)
    return localStorage.setItem(key, compressor.compress(JSON.stringify(value), { inputEncoding: "String", outputEncoding: "StorageBinaryString" }))
}

function decompressFromStore(key) {
    if(_.isEmpty(localStorage.getItem(key))) {
        return ""
    }
    return compressor.decompress(localStorage.getItem(key), { inputEncoding: "StorageBinaryString", outputEncoding: "String" })
}


function deleteFromStore(key) {
    return localStorage.removeItem(key)
}

function requiredToLogin() {
    return getFromStore(storageConstants.USER_INFO) == null && getFromStore(storageConstants.USER_LOGGED_IN) === false
}

